<template>
<section class="edit-category">
    <Loader :loading="showLoader" />
    <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
            <div class="card">
                <div class="card-title">
                    <div class="row">
                        <div class="col-12">
                            <h3 class="page-title float-left">Add Footer</h3>
                            <router-link class="float-right" to="/website-management/footer">
                                <i class="mdi mdi-window-close font-size-18 text-dark"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <form class="pt-2">
                        <div class="row">
                            <div class="col-md-5">
                                <div class="form-group">
                                    <label for="name">Logo</label>
                                    <vue-dropzone ref="myVueDropzone" id="thumbVideo " :options="dropzoneOptions" :useCustomSlot="true">
                                        <div class="dropzone-custom-content">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 24 24" fill="transparent" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload" color="">
                                                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                <polyline points="17 8 12 3 7 8"></polyline>
                                                <line x1="12" y1="3" x2="12" y2="15"></line>
                                            </svg>
                                            <div class="subtitle">
                                                <span> Drop File or </span>
                                                <span style="color: #eb2027"> Browse</span>
                                            </div>
                                        </div>
                                    </vue-dropzone>
                                </div>
                            </div>
                            <div class="col-md-7">
                                <div class="form-group">
                                    <label for="content">Content</label>
                                    <vue-editor id="content" name="content" use-custom-image-handler></vue-editor>
                                </div>
                            </div>
                        </div>
                        <h4 class="card-title mb-3 px-0 pb-2">Information</h4>
                        <div class="row" v-for="(information, index) in informations" :key="index">
                            <div class="col-md-5">
                                <div class="form-group">
                                    <label for="name">Title</label>
                                    <input type="text" v-model="information.title" class="form-control" />
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="form-group">
                                    <label for="name">Url</label>
                                    <input type="text" v-model="information.url" class="form-control" />
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group float-left mb-0 pt-3">
                                    <a @click="removeInformation(index)" v-if="informations.length > 1" class="btn btn btn-add-sub btn-outline-dark" v-b-tooltip.hover title="Delete">
                                        <i class="mdi mdi-delete"></i>
                                    </a>
                                    <a class="btn btn btn-add-sub btn-outline-primary" @click="addInformation" v-b-tooltip.hover title="Add ">
                                        <i class="mdi mdi-plus"> </i>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <h4 class="card-title mb-3 px-0 pb-2">Customer Service</h4>
                        <div class="row" v-for="(customer, index) in customerService" :key="index">
                            <div class="col-md-5">
                                <div class="form-group">
                                    <label for="name">Title</label>
                                    <input type="text" v-model="customer.title" class="form-control" />
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="form-group">
                                    <label for="name">Url</label>
                                    <input type="text" v-model="customer.url" class="form-control" />
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group float-left mb-0 pt-3">
                                    <a @click="removeCustomerService(index)" v-if="customerService.length > 1" class="btn btn btn-add-sub btn-outline-dark" v-b-tooltip.hover title="Delete">
                                        <i class="mdi mdi-delete"></i>
                                    </a>
                                    <a class="btn btn btn-add-sub btn-outline-primary" @click="addCustomerService" v-b-tooltip.hover title="Add ">
                                        <i class="mdi mdi-plus"> </i>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <h4 class="card-title mb-3 px-0 pb-2">My Account</h4>
                        <div class="row" v-for="(account, index) in myAccount" :key="index">
                            <div class="col-md-5">
                                <div class="form-group">
                                    <label for="name">Title</label>
                                    <input type="text" v-model="account.title" class="form-control" />
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="form-group">
                                    <label for="name">Url</label>
                                    <input type="text" v-model="account.url" class="form-control" />
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group float-left mb-0 pt-3">
                                    <a @click="removeMyAccount(index)" v-if="myAccount.length > 1" class="btn btn btn-add-sub btn-outline-dark" v-b-tooltip.hover title="Delete">
                                        <i class="mdi mdi-delete"></i>
                                    </a>
                                    <a class="btn btn btn-add-sub btn-outline-primary" @click="addMyAccount" v-b-tooltip.hover title="Add ">
                                        <i class="mdi mdi-plus"> </i>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <h4 class="card-title mb-3 px-0 pb-2">Social Media</h4>
                        <div class="row" v-for="(social, index) in socialMedia" :key="index">
                            <div class="col-md-5">
                                <div class="form-group">
                                    <label for="name">Icon</label>
                                    <select class="custom-select form-control" v-model="social.icon">
                                        <option selected> </option>
                                        <option value="icon-facebook-f">Facebook</option>
                                        <option value="icon-twitter"> Twitter</option>
                                        <option value="icon-instagram">Instagram</option>
                                        <option value="icon-youtube"> Youtube</option>
                                        <option value="icon-pinterest"> Pinterest</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="form-group">
                                    <label for="name">Url</label>
                                    <input type="text" v-model="social.url" class="form-control" />
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group float-left mb-0 pt-3">
                                    <a @click="removeSocialMedia(index)" v-if="socialMedia.length > 1" class="btn btn btn-add-sub btn-outline-dark" v-b-tooltip.hover title="Delete">
                                        <i class="mdi mdi-delete"></i>
                                    </a>
                                    <a class="btn btn btn-add-sub btn-outline-primary" @click="addSocialMedia" v-b-tooltip.hover title="Add ">
                                        <i class="mdi mdi-plus"> </i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group float-right mb-0 pt-3">

                                    <button class="btn btn btn-add btn-primary">Submit</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
const mediaApiURL = process.env.VUE_APP_MEDIA_SERVICE;
import vue2Dropzone from "vue2-dropzone";
import {
    VueEditor
} from "vue2-editor";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import Loader from "../../../components/Loader";
export default {
    name: "add-invoice",
    components: {
        Loader,
        VueEditor,
        vueDropzone: vue2Dropzone,
    },
    data() {
        return {
            showLoader: false,
            invoice: {
                name: "",
                active: true,
            },
            options: [],
            dropzoneOptions: {
                url: `${mediaApiURL}/file/upload`,
                maxFilesize: 10,
            },
            submitted: false,
            informations: [{
                title: '',
                subTitle: ''
            }],
            customerService: [{
                title: '',
                subTitle: ''
            }],
            myAccount: [{
                title: '',
                subTitle: ''
            }],
            socialMedia: [{
                icon: '',
                subTitle: ''
            }],
        };
    },
    mounted() {},
    methods: {
        addInformation() {
            this.informations.push({
                title: '',
                subTitle: ''
            });
        },
        removeInformation(index) {
            if (this.informations.length > 1) {
                this.informations.splice(index, 1);
            } else {
                alert('At least one Information is required.');
            }
        },

        addCustomerService() {
            this.customerService.push({
                title: '',
                subTitle: ''
            });
        },
        removeCustomerService(index) {
            if (this.customerService.length > 1) {
                this.customerService.splice(index, 1);
            } else {
                alert('At least one customer Service is required.');
            }
        },

        addMyAccount() {
            this.myAccount.push({
                title: '',
                subTitle: ''
            });
        },
        removeMyAccount(index) {
            if (this.myAccount.length > 1) {
                this.myAccount.splice(index, 1);
            } else {
                alert('At least one my Account Service is required.');
            }
        },

        addSocialMedia() {
            this.socialMedia.push({
                icon: '',
                subTitle: ''
            });
        },
        removeSocialMedia(index) {
            if (this.socialMedia.length > 1) {
                this.socialMedia.splice(index, 1);
            } else {
                alert('At least one Social Media Service is required.');
            }
        }
    },
};
</script>

<style scoped>
.dropzone {
    padding: 40px 20px !important;
    min-height: 207px !important;
}

.btn-add-sub {
    padding: 10px !important;
    margin: 7px 0px 0px 7px;
    border-radius: 2px;

}
</style>
